import React from 'react'

import HeaderOne from 'sharedComponents/HeaderOne'
import Button from 'sharedComponents/Button'
import Text from 'sharedComponents/Text'
import style from './style.module.scss'

type Props = {
  isMobile: boolean
}

function Success(props: Props) {
  const { isMobile } = props

  return (
    <>
      <HeaderOne className={style.header}>
        Welcome to Pet Notify premium.
      </HeaderOne>

      <Text className={style.text}>
        Thank you for choosing Pet Notify premium. You can now order and
        activate your pet’s Intellitag.
      </Text>

      <div className={style.buttons}>
        <Button className={style.button} to='/order-intellitag'>
          Add an Intellitag
        </Button>

        <Button className={style.button} to='/' variant='hollow'>
          Back to Pet Vault
        </Button>
      </div>
    </>
  )
}

export default Success
