import React, { useState } from 'react'
import { Link as RouterLink, Switch, Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import NewModal from 'sharedComponents/Modal'
import HeaderOne from 'sharedComponents/HeaderOne'
import ActivateIntellitagChoosePets from './ChoosePets'
import ActivateIntellitagLostPetInstructions from './LostPetInstructions'
import ActivateIntellitagActivationCode from './ActivationCode'
import { selectUser } from 'UserApp/store/user/selectors'
import style from './style.module.scss'
import Text from 'sharedComponents/Text'
import Link from 'sharedComponents/Link'
import UpgradeToPremiumActivateTag from '../UpgradeToPremiumActivateTag'
import classNames from 'classnames'
import queryString from 'query-string'
import ActivateIntellitagUpgradeSuccess from '../UpgradeToPremiumActivateTag/Success'
import ActivationSuccess from './ActivationSuccess'
import { boolean, number } from 'yup'

function ActivateIntellitag({ history }) {
  const [order, setOrder] = useState(null)
  // const [activation, setActivation] = useState('')
  // const [order22, setOrder22] = useState<any>()
  const user = useSelector(selectUser)
  const { hasSubscription, status } = user
  const [intellitagInstructions, setIntellitagInstructions] = useState<string>()

  function closeSuccess() {
    history.push('/pets')
  }

  function handleSuccessChoosePets() {
    history.push(`/pets/activate-intellitag/activation-success`)
  }

  function handleSetIntellitagInstructions(intellitagInstuctions) {
    setIntellitagInstructions(intellitagInstuctions)

    history.push(`/pets/activate-intellitag/choose-pets`)
  }

  const handleClose = () => {
    history.push('/pets')
  }

  function handleSuccessActivationCode(order) {
    setOrder(order)
    history.push(`/pets/activate-intellitag/lost-pet-instructions`)
  }

  const getActivation = (activationCode: string) => {
    return activationCode;
  }

  return (
    <Switch>
      <Route
        path='/pets/activate-intellitag/choose-pets'
        render={() => {
          if (!order) {
            return <Redirect to='/pets/activate-intellitag/activation-code' />
          }

          return (
            <ActivateIntellitagChoosePets
              order={order}
              intellitagInstructions={intellitagInstructions}
              onSuccess={handleSuccessChoosePets}
            />
          )
        }}
      />
      <Route
        path='/pets/activate-intellitag/lost-pet-instructions'
        render={() => {
          if (!order) {
            return <Redirect to='/pets/activate-intellitag/activation-code' />
          }

          return (
            <ActivateIntellitagLostPetInstructions
              onSaveIntellitagInsructions={handleSetIntellitagInstructions}
            />
          )
        }}
      />
      <Route
        path='/pets/activate-intellitag/activation-code'
        render={() => 
          <ActivateIntellitagActivationCode
            onSuccess={handleSuccessActivationCode}
          />
        }
      />
      <Route
        path='/pets/activate-intellitag/reactivate'
        render={() => (
          <>
            <HeaderOne>Reactivate Intellitag</HeaderOne>

            <Text className={style.reactivateText}>
              Intellitags require a premium subscription.
              <br />
              One subscription powers unlimited pets.
              <br />
              <Link to='/settings/upgrade'>
                Upgrade your account for $2.99 per month or $19.95 annually!
              </Link>
            </Text>
          </>
        )}
      />
      <Route
        path='/pets/activate-intellitag/selectPlan'
        element={
          (!order) ? <Redirect to='/pets/activate-intellitag/activation-code' /> : <UpgradeToPremiumActivateTag onClose={handleClose} order={order} />
        }
      />
      <Route
        path='/pets/activate-intellitag/activation-success'
        render={() => (
          <ActivationSuccess onClose={handleClose} />
        )}
      />
      <Route
          path='/pets/activate-intellitag/paymentSuccess'
          render={() => (
            // <NewModal onClose={closeSuccess} className={style.successModal}>
              <ActivateIntellitagUpgradeSuccess onClose={closeSuccess} />
            // </NewModal>
          )}
        />
      <Route
        path='/pets/activate-intellitag'
        render={(props) => (
          <>
            <HeaderOne>Activate Intellitag</HeaderOne>
            <div className={style.buttons}>
              <RouterLink
                to='/pets/activate-intellitag/activation-code'
                className={style.button}
              >
                I’ve already purchased an Intellitag
              </RouterLink>
              {hasSubscription ? (
                <>
                  <RouterLink
                    to={`/order-intellitag${props.location.search}`}
                    className={style.button}
                  >
                    I want to purchase an Intellitag
                  </RouterLink>
                  <RouterLink
                    to={(status==='premium') ? `/replace-intellitag${props.location.search}` :((status==='downgraded')?'/downgraded':((status==='past-due')?'/past-due':'/'))}
                    className={style.button}
                  >
                    I want to replace an Intellitag
                  </RouterLink>
                </>
              ) : (
                <Text className={style.text}>
                  Intellitags require a premium subscription.
                  <br />
                  One subscription powers unlimited pets.
                  <br />
                  <Link to='/settings/upgrade'>
                    Upgrade your account for $2.99 per month or $19.95 annually!
                  </Link>
                </Text>
              )}
            </div>
          </>
        )}
      />
    </Switch>
  )
}

export default ActivateIntellitag
