import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import useUser from 'UserApp/hooks/useUser';
import logo from './images/logo.svg';
import style from './style.module.scss'
import classNames from 'classnames';
import { API_URL } from 'config'
import useNotification from 'UserApp/hooks/useNotification';

const VerifyEmail = () => {
  const { setNotification } = useNotification()
  const [userData, setUserData] = useState({})
  const { user } = useUser()
  
  useEffect(() => {
    setUserData(user)
  }, [])

  // console.log(`USER :: `,userData)
  const createdAt = new Date(userData?.createdAt);
  const august9th = new Date('2024-08-09');

  // code to send the verification mail again to the user 
  const handleClick = async () => {
    try {

      if ((!userData?.isVerified) || (userData?.isVerified===null))
      {
        const response = await fetch(`${API_URL}/api/users/resend-verification`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: userData?.email }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        // console.log('Email sent successfully:', data);
        setNotification(data.message);
      }
      else
      {
        setNotification('User is already verified.')  
      }
    } catch (error) {
      console.error('There was an error sending the email:', error);
      setNotification('Failed to send email');
    }
  };
  
  return (
      (createdAt >= august9th) ?
      (
        <div>
            <img
              src={logo}
              className={classNames(style.wrapper)}
                alt='Pet Notify'
                height='37'
            />
            <h2 className={classNames(style.text)}>Please Verify Your Email</h2>
            <p className={classNames(style.message)}>
                You need to verify your email address to access this page. Please check
                your inbox for a verification email.<br/> If not received then click on the 'send verification email'.
            </p>
            <p className={classNames(style.message)}>
                Post successful email verification, click button below to access your Pet Vault.
            </p>
            <div className={classNames(style.container)}>
              <button className={classNames(style.button2)} onClick={handleClick}>Send Verification email</button>
              <button className={classNames(style.button)} >
              <a href="/" className={classNames(style.anchor)}>To Pet Vault</a>
              </button>
            </div>
            <a href="/update-email" className={classNames(style.link)}>
              Don't have access to Email
            </a>
        </div >
      )
      :
      (<div>
        <img
          src={logo}
          className={classNames(style.wrapper)}
            alt='Pet Notify'
            height='37'
        />
        <h2 className={classNames(style.text)}>Email verification pending</h2>
        <p className={classNames(style.message)}>
          Please verify your email to ensure accurate communications if you or your pet(s) are in need of help.
          <br/> Click on the button below to send verification email.
        </p>
        <p className={classNames(style.message)}>
            Post successful email verification, click button below to access your Pet Vault.
        </p>
        <div className={classNames(style.container)}>
          <button className={classNames(style.button2)} onClick={handleClick}>Send Verification email</button>
          <button className={classNames(style.button)} >
          <a href="/" className={classNames(style.anchor)}>To Pet Vault</a>
          </button>
        </div>
        <a href="/update-email" className={classNames(style.link)}>
          Don't have access to Email
        </a>
    </div >)
  );
};

export default VerifyEmail;