import React, { useContext, useEffect, useState } from 'react'
import { Route, Switch, useLocation, useHistory } from 'react-router-dom'

import ReactGA from 'react-ga'
import * as Sentry from '@sentry/react'
import { GOOGLE_ANALYTICS } from 'config'
import mixPanel from 'services/mix-panel'
import { Loader } from '@pet-notify/ui'

import AccountInactiveScreen from 'UserApp/screens/AccountInactive'
import SigninScreen from 'UserApp/screens/Signin'
import ForgotPasswordScreen from 'UserApp/screens/ForgotPassword'
import ForgotPasswordCheckEmailScreen from 'UserApp/screens/ForgotPassword/CheckEmail'
import ResetPasswordScreen from 'UserApp/containers/ResetPasswordScreen'
import SetPasswordScreen from 'UserApp/screens/SetPassword'
import Signout from 'UserApp/containers/Signout'
import SignupScreen from 'UserApp/screens/Signup'
import Invite from 'UserApp/containers/Invite'
import VerifyAccountScreen from 'UserApp/containers/VerifyAccountScreen'
import SettingsScreen from 'UserApp/screens/Settings'
import AlarmScreen from 'UserApp/containers/Alarm/Screen'
import PrivateRoute from 'UserApp/containers/PrivateRoute'
import PetVaultScreen from 'UserApp/containers/PetVaultScreen'
import ContactsScreen from 'UserApp/containers/Contacts/Screen'
import TasksScreen from 'UserApp/containers/Tasks/Screen'
import OrderIntellitagModal from 'UserApp/containers/OrderIntellitagModal'
import ReplaceIntellitagOrderModal from 'UserApp/components/ReplaceIntellitagOrderModal'
import { ColorContext, COLORS } from 'ColorContext'
import { useDispatch, useSelector } from 'react-redux'
import { fetch } from 'UserApp/store/user/actions'
import withEmailVerification from '../MailVerification/withEmailVerification'
import VerifyEmail from '../MailVerification/verifyEmail'
import DowngradePopup from '../DowngradedPopup'
import PastDuePopup from '../PastDuePopup'
import UpdatedTerms from '../UpdatedTerms'
import UpdateEmail from '../UpdateEmail'

function App() {
  const location = useLocation()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const { setColor } = useContext(ColorContext)

  async function fetchUser() {
    await dispatch(fetch())
    setIsLoading(false)
  }

  useEffect(() => {
    fetchUser()
  }, [])

  useEffect(() => {
    const path = location.pathname + location.search

    if (GOOGLE_ANALYTICS) {
      ReactGA.pageview(path)
    }

    mixPanel.track(`Page view`, { url: path })

    window.scrollTo(0, 0)
  }, [location.pathname, location.search])

  useEffect(() => {
    if (user) {
      const { id, email } = user
      Sentry.configureScope((scope) => {
        scope.setUser({ id, email })
      })
    } else {
      Sentry.configureScope((scope) => {
        scope.setUser({})
      })
    }
  }, [user])

  useEffect(() => {
    if (
      user &&
      !user.isActive &&
      !(
        location.pathname === '/account-inactive' ||
        location.pathname === '/signout'
      )
    ) {
      history.replace('/account-inactive')
    }
  }, [user, location])

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <Switch>
        <Route
          path='/alarm'
          render={() => {
            setColor(COLORS.MINT)
            return null
          }}
        />
        <Route
          path='/settings'
          render={() => {
            setColor(COLORS.GRAPE)
            return null
          }}
        />
        <Route
          path='/tasks'
          render={() => {
            setColor(COLORS.FOREST_GREEN)
            return null
          }}
        />
        <Route
          path='/contacts'
          render={() => {
            setColor(COLORS.ORANGE)
            return null
          }}
        />
        <Route
          path='/'
          render={() => {
            setColor(null)
            return null
          }}
        />
      </Switch>
      <Switch>
        <PrivateRoute path='/alarm' component={withEmailVerification(AlarmScreen)} />
        <PrivateRoute path='/settings' component={withEmailVerification(SettingsScreen)} />

        <PrivateRoute path='/tasks/new' component={withEmailVerification(TasksScreen)} />
        <PrivateRoute path='/tasks/:id' component={withEmailVerification(TasksScreen)} />
        <PrivateRoute path='/tasks' component={withEmailVerification(TasksScreen)} />

        <PrivateRoute path='/contacts/new' component={withEmailVerification(ContactsScreen)} />
        <PrivateRoute path='/contacts/:id' component={withEmailVerification(ContactsScreen)} />
        <PrivateRoute path='/contacts' component={withEmailVerification(ContactsScreen)} />

        <PrivateRoute path='/address/new' component={withEmailVerification(PetVaultScreen)} />
        <PrivateRoute path='/address/edit' component={withEmailVerification(PetVaultScreen)} />
        <PrivateRoute
          path='/pets/activate-intellitag'
          component={withEmailVerification(PetVaultScreen)}
        />
        <PrivateRoute path='/pets/new' component={withEmailVerification(PetVaultScreen)} />
        <PrivateRoute path='/pets/:id' component={withEmailVerification(PetVaultScreen)} />
        <PrivateRoute
          path='/account-inactive'
          component={AccountInactiveScreen}
        />
        <PrivateRoute path='/updated-terms' component={UpdatedTerms} />

        <Route path='/verify-account' component={VerifyAccountScreen} />

        <Route
          path='/forgot-password/check-email'
          component={ForgotPasswordCheckEmailScreen}
        />
        <Route path='/forgot-password' component={ForgotPasswordScreen} />
        <Route path='/reset-password' component={ResetPasswordScreen} />
        <Route path='/set-password' component={SetPasswordScreen} />

        <Route path='/signout' component={Signout} />
        <Route path='/signin' component={SigninScreen} />
        <Route path='/signup' component={SignupScreen} />
        <Route path='/invite' component={Invite} />
        <Route path='/homeinvite' component={Invite} />
        <Route path='/pricing' component={Invite} />
        <PrivateRoute path='/verify-email' component={VerifyEmail} />
        <PrivateRoute path='/update-email' component={UpdateEmail} />
        <Route path='/downgraded' component={DowngradePopup} />
        <Route path='/past-due' component={PastDuePopup} />
        <PrivateRoute path='/' component={withEmailVerification(PetVaultScreen)} />
      </Switch>
      <Switch>
        <PrivateRoute
          path='/replace-intellitag'
          component={withEmailVerification(ReplaceIntellitagOrderModal)}
        />
        <PrivateRoute
          path='/order-intellitag'
          component={withEmailVerification(OrderIntellitagModal)}
        />
      </Switch>
    </>
  )
}

export default App
