import React, { useEffect, useState } from 'react';
import {useHistory} from 'react-router-dom';
import useUser from 'UserApp/hooks/useUser';
import logo from './images/logo.svg';
import style from './style.module.scss'
import classNames from 'classnames';
import { API_URL } from 'config'
import useNotification from 'UserApp/hooks/useNotification';
import HeaderOne from 'sharedComponents/HeaderOne';
import Text from 'sharedComponents/Text';
import Label from 'sharedComponents/Label';
import Input from 'sharedComponents/Input';
import Button from 'sharedComponents/Button';
import Error from 'sharedComponents/Error';

const UpdateEmail = () => {
  const { setNotification } = useNotification()
  const [userData, setUserData] = useState({})
  const [email, setEmail] = useState('')
  const [confirmEmail, setConfirmEmail] = useState('')
  const [error, setError] = useState('')
  const { user } = useUser()
  const history = useHistory()
  
  useEffect(() => {
    setUserData(user)
  }, [])
  console.log(`USER DATA ::`,userData)

  // code to send the verification mail again to the user 
  const handleSubmit = async () => {
    if (!email || !confirmEmail) {
      setError('Both fields are required.');
      return;
    }

    if (email !== confirmEmail) {
      setError('Emails do not match.');
      return;
    }

    // Clear errors and proceed
    setError('');
    history.push('/verify-email')
    setNotification('Email updated')
  };
  
  return (
    <>
    <div>
      <img
        src={logo}
        className={classNames(style.wrapper)}
          alt='Pet Notify'
          height='37'
      />
      <HeaderOne className={style.title}>Update your Email</HeaderOne>
      {/* <h2 className={classNames(style.text)}>Update your Email</h2> */}
      {/* <p className={classNames(style.message)}>
        Update your email if you have lost access to email with which you registered for Pet Notify
      </p> */}
      <Text className={style.subTitle}>
        Update your email if you have lost access to email with which you registered for Pet Notify
      </Text>
      <Label htmlFor='signup-email'>Email</Label>
      <Input
        type='email'
        name='email'
        id='signup-email'
        value={email}
        onChange={({ name, value }) =>{setEmail(value)}}
      />

      <Label htmlFor='signup-confirm-email'>Confirm email</Label>
      <Input
        type='email'
        name='confirmEmail'
        id='signup-confirm-email'
        value={confirmEmail}
        onChange={({ name, value }) =>{setConfirmEmail(value)}}
      />
      <p className={classNames(style.message)}>
          Post successful email updation, check your updated email inbox for verification email.
      </p>
      <Text className={style.subTitle}>
        Post successful email updation, check your updated email inbox for verification email.
      </Text>
      {error && <Error>{error}</Error>}
      <div className={classNames(style.container)}>
        <Button
            type='submit'
            onClick={handleSubmit}
        >
          Submit
        </Button>
        <button className={classNames(style.button2)} onClick={handleSubmit}>Submit</button>
      </div>
    </div >
    </>
  )
};

export default UpdateEmail;