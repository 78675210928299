import React, { useState } from 'react'
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'

import { authenticate } from 'UserApp/store/user/actions'
import Layout from 'components/Layout/SplitLayout'
import {
  Text,
  Link,
  Header,
  Input,
  Loader,
  Label,
  Checkbox,
  Button,
  PasswordInput,
  FieldGroup,
  Fields,
} from '@pet-notify/ui'

function SigninScreen() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string>()
  const { register, handleSubmit } = useForm()

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true)
    try {
      await dispatch(authenticate(data))
      history.push(location.state?.originalPath || '/')
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message)
      }
    } finally {
      setIsLoading(false)
    }
  })

  return (
    <Layout alternateBackground2>
      <Header as='h1' size='xxlarge' mb='8'>
        Sign in
      </Header>

      <form onSubmit={onSubmit} className='mb-8'>
        <Fields>
          <FieldGroup>
            <Label htmlFor='signin-email'>Email</Label>
            <Input type='email' id='signin-email' {...register('email')} />
          </FieldGroup>

          <FieldGroup>
            <Label htmlFor='signin-password'>Password</Label>
            <PasswordInput id='signin-password' {...register('password')} />
          </FieldGroup>

          <FieldGroup>
            <Checkbox
              label='Keep me logged in'
              id='signin-keep-me-signed-in'
              {...register('keepMeSignedIn')}
            />
          </FieldGroup>

          {error && <Text variant='error'>{error}</Text>}
        </Fields>

        {isLoading ? <Loader /> : <Button type='submit'>Sign in</Button>}
      </form>

      <ul className='links'>
        <li>
          <Link as={RouterLink} to='/forgot-password'>
            Forgot Password?
          </Link>
        </li>
        <li>
          <Link as={RouterLink} to='/signup'>
            Create account
          </Link>
        </li>
      </ul>
    </Layout>
  )
}

export default SigninScreen
