import React from 'react'
import PropTypes from 'prop-types'

import api from 'services/pet-notify-api'

import InvitationScreen from 'ContactApp/screens/Invitation'

class InvitationScreenContainer extends React.PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        token: PropTypes.string,
      }),
    }),
  }

  state = {
    isLoading: true,
    isProcessing: false,
    didAccept: false,
    didDecline: false,
    error: '',
  }

  async componentDidMount() {
    const { match } = this.props
    const { params } = match
    const { token } = params

    try {
      const { user } = await api.contactInvitations.view(token)
      this.setState({ isLoading: false, user })
    } catch (error) {
      this.setState({ isLoading: false, error })
    }
  }

  handleSubmit = async (data) => {
    const { match } = this.props
    const { params } = match
    const { token } = params

    if (!data.agree) {
      throw new Error('You must agree')
    }

    this.setState({ isProcessing: true })

    try {
      await api.contactInvitations.accept(token, data)
      this.setState({ isProcessing: false, didAccept: true })
    } catch (error) {
      this.setState({ isProcessing: false })
      throw error
    }
  }

  handleDecline = async () => {
    const { match } = this.props
    const { params } = match
    const { token } = params
    this.setState({ isProcessing: true })

    try {
      await api.contactInvitations.decline(token)
      this.setState({ isProcessing: false, didDecline: true })
    } catch (error) {
      this.setState({ isProcessing: false })
      throw error
    }
  }

  generateProps = () => {
    const { match } = this.props
    const { params } = match
    const { token } = params
    return {
      ...this.props,
      ...this.state,
      token,
      onSubmit: this.handleSubmit,
      onDecline: this.handleDecline,
    }
  }
  render() {
    const props = this.generateProps()

    return <InvitationScreen {...props} />
  }
}

export default InvitationScreenContainer
